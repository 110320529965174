import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { UserType, StatusType,TripStatusType } from '../../helpers/fixcodes.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Template } from 'devextreme-react/core/template';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
const position = { of: '#historydiv' };

export const contextMenuItems = [
    { text: 'Assign', icon: 'fas fa-link', value: parseInt(StatusType.GreeterAssigned) },
    { text: 'UnAssign', icon: 'fas fa-unlink', value: parseInt(StatusType.GreeterRejected) },
    { text: 'Complete Job', icon:'fas fa-check', value: parseInt(TripStatusType.TripComplete) },
    { text: 'Cancel Job', icon:'fas fa-times', value: parseInt(TripStatusType.Voided) },
  ];

let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

export class GreeterDispatchComponent extends Component {
    static displayName = GreeterDispatchComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            greeterJobSource: [],
            serviceGreeters: [],
            dispatchGreeterId: 0,
            filterGreeterId: 0,
            isGreeterMultipleAssignedSubmited: false,
            selectedRowKeys: [],
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            customers: [],
            filterCustomerId: 0,
            jobType: 0,
            errors: {
                dispatchGreeterId: "",
            }
        }
        this.toolbarItemRender = this.toolbarItemRender.bind(this);
        this.unAssignGreeter = this.unAssignGreeter.bind(this);
        this.handleMultipleJobAssign = this.handleMultipleJobAssign.bind(this);
        this.handleMultipleRideSelectionChanged = this.handleMultipleRideSelectionChanged.bind(this);
        this.handleSearchFromDateChange = this.handleSearchFromDateChange.bind(this);
        this.handleSearchToDateChange = this.handleSearchToDateChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });

        await this.getGreeters();
        await this.getJobs();
        await this.getCustomers();
        this.setState({
            loadPanelVisible: false
        });
    }

    getJobs = async (job_type) => {
        try {
            var request = {};            
            request.user_id = parseInt(sessionStorage.getItem('session_userId'));;
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            request.customer_id = parseInt(this.state.filterCustomerId);
            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            request.from_date = formatDate(this.state.searchFromDate, "MM/dd/yyyy hh:mm");
            request.to_date = formatDate(this.state.searchToDate, "MM/dd/yyyy hh:mm");
            request.greeter_id = parseInt(this.state.filterGreeterId);
            request.job_type = parseInt(this.state.jobType);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'greeter/meetgreet/jobs', requestParams);
            const data = await response.json();
            this.setState({
                greeterJobSource: data.meetGreetJob,
                isGridHasRow: (data.meetGreetJob !== null && data.meetGreetJob.length > 0) || false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getJobs function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getCustomers() {
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();
            //debugger;
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleDispatchSearch = async () => {
        this.getJobs();
    }

    handleAssignedJobs = async () => {
        this.setState({
            jobType: 1
        });
        setTimeout(() => this.getJobs(), 500);
    }

    handleNotAssignedJobs = async () => {
        this.setState({
            jobType: 2
        });
        setTimeout(() => this.getJobs(), 500);
    }

    handleAllJobs = async () => {
        this.setState({
            jobType: 0
        });
        setTimeout(() => this.getJobs(), 500);
    }

    async handleReset() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            greeterJobSource: [],
            dispatchGreeterId: 0,
            filterGreeterId: 0,
            isGreeterMultipleAssignedSubmited: false,
            selectedRowKeys: [],
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            filterCustomerId: 0,
            errors: {
                dispatchGreeterId: "",
            }
        });
        setTimeout(() => this.getJobs(), 500);
    }

    async getGreeters() {
        try {
            var request = {};
            request.user_id = 0; //parseInt(sessionStorage.getItem('session_userId'));
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            request.user_type_id = parseInt(UserType.GREETER);
            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/users/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                serviceGreeters: data.userDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getGreeters function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleGreeterChange = (selectedOption) => {
        this.setState({ dispatchGreeterId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleFilterGreeterChange = (selectedOption) => {
        this.setState({ filterGreeterId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleSearchFromDateChange(date) {
        let fromDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchFromDate: fromDateValue })
    }
    handleSearchToDateChange(date) {
        let toDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchToDate: toDateValue })
    }

    handleCustomerChange = (selectedOption) => {        
        this.setState({ filterCustomerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    toolbarItemRender() {
        return (
            <div className="row">
                <div className=" col-lg-7 col-md-7 col-sm-7 col-xs-7 pr-0">
                    <div>
                        <SelectBox
                            dataSource={this.state.serviceGreeters.length > 0 ? this.state.serviceGreeters : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Greeter"
                            name="dispatchGreeterId"
                            defaultValue={0}
                            value={this.state.dispatchGreeterId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleGreeterChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                            width="100%"
                        />
                    </div>
                    {this.state.errors["dispatchGreeterId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchGreeterId"]}</span>}
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                    <button type="button" id="btnSaveAssign" className="btn btn-dark btn-sm" title="Assign Ride" onClick={this.handleMultipleJobAssign}>
                        {this.state.isGreeterMultipleAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                        {!this.state.isGreeterMultipleAssignedSubmited && <span><i className="fas fa-check"></i> Assign</span>}
                    </button>
                </div>
            </div>
        );
    }

    onToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isGridHasRow;
            e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'multipleGreeter'
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    //text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "GreeterJobs");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            },
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'refresh',
                        onClick: this.getJobs.bind(this),
                        hint: 'Refresh'
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }
    }

    handleMultipleJobAssign = async (e) => {
        e.preventDefault();
        let jobrides = [];
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            await Swal.fire({
                title: selectedRowsData.length > 1 ? "<small>Multiple Ride Assignment</small>" : "<small>Ride Assignment</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                confirmButtonColor: '#343a40',
                dangerMode: false,
                width: "25%",
                //allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askMultipleVehicleAssignment(true);
        if (!isConfirm) {
            return;
        }

        if (this.handleMultipleJobAssignValidation()) {
            this.setState({ isGreeterMultipleAssignedSubmited: true });
            for (let i = 0; i < selectedRowsData.length; i++) {
                jobrides.push(selectedRowsData[i].trip_id);
            }

            if (this.state.dispatchGreeterId > 0) {
                var greeterAssignRequest = {};
                //greeterAssignRequest.trip_id = parseInt(jobrides[0]);
                greeterAssignRequest.multiple_trip_id = jobrides;
                greeterAssignRequest.greeter_id = parseInt(this.state.dispatchGreeterId);
                greeterAssignRequest.greeter_charge = parseFloat(0.00);
                greeterAssignRequest.user_id = parseInt(sessionStorage.getItem('session_userId'));
                greeterAssignRequest.is_assigned = true;
                greeterAssignRequest.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
                greeterAssignRequest.branch_id = parseInt(sessionStorage.getItem('session_branchId'));

                const requestParams = getRequestParams('POST', greeterAssignRequest);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'greeter/job/assign', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'info' : 'error',
                    title: data.isSuccess ? '<small>Job Assigned</small>' : '<small>Oops...</small>',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        this.setState({
                            isGreeterMultipleAssignedSubmited: false, selectedRowKeys: [], dispatchGreeterId: 0,
                        });
                        setTimeout(() => this.getJobs(), 500);
                    } else {
                        this.setState({ isGreeterMultipleAssignedSubmited: false })
                    }
                });
            }
        }
    }

    handleMultipleJobAssignValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.dispatchGreeterId = "";
        if (this.state.dispatchGreeterId === 0 && this.state.dispatchGreeterId === 0) {
            formIsValid = false;
            errors.dispatchGreeterId = "Please select greeter.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    async unAssignGreeter(e, dataResultSet) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }

            let jobrides = [];
            jobrides.push(dataResultSet.trip_id);
            var greeterUnAssignRequest = {};
            greeterUnAssignRequest.multiple_trip_id = jobrides;
            greeterUnAssignRequest.greeter_id = parseInt(dataResultSet.greeter_id);
            greeterUnAssignRequest.greeter_charge = parseFloat(dataResultSet.transaction_amount);
            greeterUnAssignRequest.user_id = parseInt(sessionStorage.getItem('session_userId'));
            greeterUnAssignRequest.is_assigned = false;
            greeterUnAssignRequest.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            greeterUnAssignRequest.branch_id = parseInt(sessionStorage.getItem('session_branchId'));

            const requestParams = getRequestParams('POST', greeterUnAssignRequest);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'greeter/job/assign', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'info' : 'error',
                title: data.isSuccess ? '<small>Job UnAssigned</small>' : '<small>Oops...</small>',
                confirmButtonColor: '#343a40',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    this.setState({
                        isGreeterMultipleAssignedSubmited: false, selectedRowKeys: [], dispatchGreeterId: 0,
                    });
                    setTimeout(() => this.getJobs(), 500);
                } else {
                    this.setState({ isGreeterMultipleAssignedSubmited: false })
                }
            });
        } catch (e) {
            //debugger;
        }
    }

    async askMultipleVehicleAssignment(blflag) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure?</small>",
            html: blflag === true ? "<small>Do you want to Assign greeter to selected jobs?" : "Do you want to Unassign greeter to selected jobs?</small>",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#343a40',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            //allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: '<small>UnAssign Greeter</small>',
            html: "<small>Are you sure want to unassign greeter?</small>",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#343a40',
            confirmButtonText: 'Unassign',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    handleMultipleRideSelectionChanged(e) {
        this.setState({
            selectedRowKeys: e.selectedRowKeys
        });
    }

    handleRowPrepared(e) {        
        if (e.rowType === 'data') {
            if(e.data.greeter_id > 0) { 
                e.rowElement.classList.add("bgGreeterAssigned");
            }
            else if(e.data.is_vip_job === true) { 
                e.rowElement.classList.add("bgVipJob");
            }
        }
    }

    editTrip = (e, tripData) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/booking/greeter/new',
            state: { data: tripData, sourceUrl: "/greeter",trip_id: tripData.trip_id }
        });
        window.location.reload(false);
    }

    itemClick = (e, tripData) => {
        debugger;
    }

    render() {
        const renderGridCell = (data) => {
            // return <div className="btn-group">
            //     <button type="button" className="btn btn-outline-secondary btn-sm border-0 dropdown-toggle dropdown-icon" data-toggle="dropdown" ><i class="fas fa-ellipsis-v"></i></button>
            //     {/* <button type="button" className="btn btn-outline-secondary btn-sm dropdown-toggle dropdown-icon" >
            //         <span className="sr-only">Toggle Dropdown</span>
            //     </button> */}
            //     <div className="dropdown-menu" role="menu">
            //         <NavLink id="routeNoShow" tag={Link} to="/noshow/list" className="dropdown-item" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink>
            //         <NavLink id="routeDriverMessageList" tag={Link} to="/drivermessage/list" className="dropdown-item" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink>
            //         <NavLink id="routeLoggedDrivers" tag={Link} to="/loggeddrivers/list" className="dropdown-item" title="Logged Drivers"><i className="fas fa-user"></i> Logged Drivers</NavLink>
            //         <NavLink id="routeLoggedVehicles" tag={Link} to="/loggedvehicles/list" className="dropdown-item" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink>
            //         <NavLink id="routeDriverMessge" tag={Link} to="#" className="dropdown-item" onClick={(e) => { this.sendDriverMessageControl(e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink>
            //         <NavLink id="routeTrackLog" tag={Link} to="/gpstrack/list" className="dropdown-item" title="Vehicle Gps Log"><i className="fas fa-sms"></i> Track Log</NavLink>
            //     </div>
            // </div>;
            //debugger;            
            var activeContextMenus = contextMenuItems;
            if(parseInt(data.data.status_id) === parseInt(StatusType.GreeterAssigned))
            {
                activeContextMenus = contextMenuItems.filter(item => item.value !== parseInt(data.data.status_id));
            }

            return <div>
                <button id="image" type="button" className="btn btn-outline-secondary btn-sm border-0"><i className="fas fa-ellipsis-v"></i></button>
                <ContextMenu
                    dataSource={activeContextMenus}
                    width={200}
                    target="#image"
                    onItemClick={(e) => { this.itemClick(e, data.data) }}
                />
            </div>;
        }

        const renderFlightCell = (data) => {
            return <div>
                <img src={data.data.airline_icon} width="20" />&nbsp;{data.data.airline_info}
            </div>;
        }

        const renderGreeterCell = (data) => {
            return <div>
                {
                    parseInt(data.data.status_id) === parseInt(StatusType.GreeterAssigned) ?
                        <a href="#" className="fas fa-unlink" title="UnAssign Greeter" onClick={(e) => { this.unAssignGreeter(e, data.data) }}></a>
                        : null
                }
                &nbsp;{data.data.greeter_name}
            </div>;
        }

        const renderTripGridCell = (data) => {
            return <div>
                <a href="#" title={"Edit booking " + data.data.trip_id} onClick={(e) => { this.editTrip(e, data.data) }} ><span className="rideTextColor">{data.data.trip_id}</span></a>&nbsp;&nbsp;              
            </div>;
        }

        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Greeter Dispatch</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.searchFromDate}
                                            value={this.state.searchFromDate}
                                            onValueChanged={this.handleSearchFromDateChange}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.searchToDate}
                                            value={this.state.searchToDate}
                                            onValueChanged={this.handleSearchToDateChange}
                                            width="100%"
                                            acceptCustomValue={false} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Select Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customers.length > 0 ? this.state.customers : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.filterCustomerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Select Greeter&nbsp;</label>
                                        <SelectBox
                                            dataSource={this.state.serviceGreeters.length > 0 ? this.state.serviceGreeters : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Greeter"
                                            name="filterGreeterId"
                                            defaultValue={0}
                                            value={this.state.filterGreeterId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFilterGreeterChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div>&nbsp;</div>
                                    <button type="submit" className="btn btn-dark btn-sm" onClick={this.handleDispatchSearch} data-slide="true">
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i></span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-default btn-sm mr-2" onClick={this.handleReset} data-slide="true">
                                        <span title="Reset"><i className="fas fa-eraser" title="Reset"></i></span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm mr-1" onClick={this.handleAllJobs} data-slide="true">
                                        <span title="All"><i className="fas fa-tasks"></i> All</span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm mr-1" onClick={this.handleAssignedJobs} data-slide="true">
                                        <span title="Assigned"><i className="fas fa-check-double"></i> Assigned</span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm" onClick={this.handleNotAssignedJobs} data-slide="true">
                                        <span title="Pending"><i className="far fa-clock"></i> Pending</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className='card card-grid'>
                                <div className='card-body'>
                                    <DataGrid
                                        ref={(ref) => this.dataGrid = ref}
                                        dataSource={this.state.greeterJobSource}
                                        keyExpr="trip_id"
                                        id="dispatch-greeter-container"
                                        name="gridGreeterContainer"
                                        width="100%"
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        onRowPrepared={this.handleRowPrepared}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.handleMultipleRideSelectionChanged}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                            //deferred={true}
                                        />
                                        {/* <Column dataField="trip_id" width={35} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell}  alignment="left" /> */}
                                        {/* <Column dataField="booking_id" caption="Booking No" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} /> */}
                                        <Column dataField="trip_id" caption="Trip#" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} alignment="left" cellRender={renderTripGridCell} />
                                        <Column dataField="pickup_address" caption="Airport" allowSearch={true} allowFiltering={false} allowSorting={true} width={60} />
                                        <Column dataField="pickup_time" caption="Pick-up Date Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} dataType="datetime" format="MM/dd/yyyy HH:mm" />
                                        <Column dataField="airline_info" caption="Flight" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderFlightCell} />
                                        {/* <Column dataField="terminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} /> */}
                                        <Column dataField="pax_name" caption="Pax Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="no_of_pax" caption="Pax#" allowSearch={true} allowFiltering={false} allowSorting={true} width={50} alignment="left" />
                                        <Column dataField="mobile" caption="Mobile#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} alignment="left" />
                                        <Column dataField="email" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={170} />                                        
                                        <Column dataField="greeter_name" caption="Greeter Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGreeterCell} />
                                        <Column dataField="customer_name" caption="Greeter Company" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                        <Column dataField="dropoff_address" caption="Drop Off" allowSearch={true} allowFiltering={false} allowSorting={true} width={350} />
                                        {/* <Column dataField="airline_code" caption="Airline" allowSearch={true} allowFiltering={false} allowSorting={true} width={60} /> */}                                        
                                        <Column dataField="driver_info" caption="Driver" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                        <Column dataField="status_name" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="trip_ref_no" caption="Reference No" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <Template name="multipleGreeter" render={this.toolbarItemRender} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="GreeterJobs" />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}