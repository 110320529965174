export const DateFormat = {
    DateTime24WesternFormat: "MM/dd/yyyy HH:mm",
    DateTime12WesternFormat: "MM/dd/yyyy hh:mm",
    DateTime24WesternLongFormat: "MM/dd/yyyy HH:mm:ss",
    DateWesternFormat: "MM/dd/yyyy",

    DateTime24NorthFormat: "dd/MM/yyyy HH:mm",
    DateTime12NorthFormat: "dd/MM/yyyy hh:mm",
    DateTime24NorthLongFormat: "dd/MM/yyyy HH:mm:ss",
    DateNorthFormat: "dd/MM/yyyy",

    Time24Format: "HH:mm",
    Time12Format: "hh:mm",
    Time24FormatLong: "HH:mm:ss",
    Time24FormatLongAMPM: "hh:mm:ss a",
};

export const UserType = {
    SYSTEMADMINISTRATOR:1,
    ADMINISTRATOR:2,
    ACCOUNT:3,
    OPERATION:4,
    PILOT:5,
    FLIGHTATTENDANT:6,
    DRIVER:7,
    TOURGUIDE:8,
    GREETER:9,
    COMMISSIONAGENT:10,
    CREW:11,
    SERVICEUSER:12,
    DISPATCHER:13,
    RESERVATIONAGENT:14,
    MANAGEMENT:15,
    FRANCHISEE:16,
    BILLING:17,
    DRIVERRELATION:18,
    AFFILIATE: 19,
}

export const StatusType = {    
    NotAssigned:1510,
    GreeterAssigned:1511,
    GreeterAcknoledged:1512,
    GreeterAccepted:1513,
    GreeterRejected:1514,
    GreeterAtSite:1515,
    PassangerAtSite:1516,
    PassangerNotAtSite:1517,
    GreeterNotAtSite :1518,
    Complete:1519,
}

export const TripStatusType = {    
    Open:1010,
    FleetAssigned:1011,
    FleetUnassigned:1012,
    ChauffeurAcknoledged:1013,
    ChauffeurAccepted:1014,
    ChauffeurRejected:1015,
    FleetEnroute:1016,
    ChauffeuratSite:1017,
    PassangerBoarded:1018,
    PassangerNotatSite:1019,
    ChauffeurNotatSite:1020,
    Voided:1021,
    PassangerDropped:1022,
    TripCompleteFleetBreakdown:1023,
    TripComplete:1024,
    Autovoided:1025,
}

export const CustomerType = {    
    Affiliates:1,
    Airline:2,
    Corporate:3,
    CorporateContract:4,
    EmployeeTransfer:5,
    Church:6,
    Hospital:7,
    Hotels:8,
    IndividualWeb:9,
    PassengerService:10,
    School:11,
    TourOperator:12,
    TravelAgents:13,
}