import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { UserType, CustomerType,TripStatusType } from '../../helpers/fixcodes.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Modal from 'react-modal'; //npm install --save react-modal
import { toast } from 'react-toastify'; //npm i react-toastify --save

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '30%',
    }
};

let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

export class ViewBookingComponent extends Component {
    static displayName = ViewBookingComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            cancelModalIsOpen: false,
            completeModalIsOpen: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            bookingSource: [],
            serviceGreeters: [],
            id: 0,
            dispatchGreeterId: 0,
            filterGreeterId: 0,
            isGreeterMultipleAssignedSubmited: false,
            selectedRowKeys: [],
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            customers: [],
            filterCustomerId: 0,
            showCustomer: true,
            showGreeter: true,
            cancelReason:"",
            cancelTripId: 0,
            completeReason:"",
            completeTripId: 0,
            jobType: 0,
            errors: {
                dispatchGreeterId: "",
                cancelReason:"",
                completeReason:"",
            }
        }
        this.handleSearchFromDateChange = this.handleSearchFromDateChange.bind(this);
        this.handleSearchToDateChange = this.handleSearchToDateChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });

        await this.getGreeters();
        await this.getBookings();
        await this.getCustomers();
        
        switch (parseInt(this.state.sessionUser.customerType)) {
            case parseInt(CustomerType.IndividualWeb):
            case parseInt(CustomerType.PassengerService):
            case parseInt(CustomerType.Corporate):
            case parseInt(CustomerType.CorporateContract):
                this.setState({
                    showCustomer: false,
                    showGreeter: false
                });
                break;
            default:
                this.setState({
                    showCustomer: true,
                    showGreeter: true
                });
                break;
        }

        this.setState({
            loadPanelVisible: false
        });
    }

    getBookings = async () => {
        try {
            var request = {};
            request.user_id = parseInt(sessionStorage.getItem('session_userId'));;
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            switch (parseInt(this.state.sessionUser.customerType)) {
                case parseInt(CustomerType.IndividualWeb):
                case parseInt(CustomerType.PassengerService):
                case parseInt(CustomerType.Corporate):
                case parseInt(CustomerType.CorporateContract):
                    request.customer_id = parseInt(this.state.sessionUser.customerId);
                    break;
                default:
                    request.customer_id = parseInt(this.state.filterCustomerId);
                    break;
            }

            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            request.from_date = formatDate(this.state.searchFromDate, "MM/dd/yyyy hh:mm");
            request.to_date = formatDate(this.state.searchToDate, "MM/dd/yyyy hh:mm");
            request.greeter_id = parseInt(this.state.filterGreeterId);
            request.job_type = parseInt(this.state.jobType);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/' + this.state.id, requestParams);
            const data = await response.json();
            this.setState({
                bookingSource: data.bookings,
                isGridHasRow: (data.bookings !== null && data.bookings.length > 0) || false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getBookings function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getCustomers() {
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();            
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleBookingSearch = async () => {
        this.getBookings();
    }

    handleAllJobs = async () => {
        this.setState({
            jobType: 0
        });
        setTimeout(() => this.getBookings(), 500);
    }

    handlePendingJobs = async () => {
        this.setState({
            jobType: 1
        });
        setTimeout(() => this.getBookings(), 500);
    }

    handleCompletedJobs = async () => {
        this.setState({
            jobType: 2
        });
        setTimeout(() => this.getBookings(), 500);
    }

    handleCancelledJobs = async () => {
        this.setState({
            jobType: 3
        });
        setTimeout(() => this.getBookings(), 500);
    }

    async handleReset() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            isGridHasRow: false,
            dispatchSource: [],
            startTime: new Date(new Date(currentTime).setDate(currentTime.getDate())),
            endTime: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            bookingSource: [],
            filterGreeterId: 0,
            searchFromDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatDate(startTime, "MM/dd/yyyy hh:mm"),
            filterCustomerId: 0,
            dispatchGreeterId: 0,
            errors: {
                dispatchGreeterId: "",
            }
        });
        setTimeout(() => this.getBookings(), 500);
    }

    async getGreeters() {
        try {
            var request = {};
            request.user_id = 0; //parseInt(sessionStorage.getItem('session_userId'));
            request.service_provider_id = parseInt(sessionStorage.getItem('session_accountId'));
            request.user_type_id = parseInt(UserType.GREETER);
            request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/users/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                serviceGreeters: data.userDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getGreeters function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleFilterGreeterChange = (selectedOption) => {
        this.setState({ filterGreeterId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleSearchFromDateChange(date) {
        let fromDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchFromDate: fromDateValue })
    }
    handleSearchToDateChange(date) {
        let toDateValue = formatDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchToDate: toDateValue })
    }

    handleCustomerChange = (selectedOption) => {
        switch (parseInt(this.state.sessionUser.customerType)) {
            case parseInt(CustomerType.IndividualWeb):
            case parseInt(CustomerType.PassengerService):
            case parseInt(CustomerType.Corporate):
            case parseInt(CustomerType.CorporateContract):
                this.setState({ filterCustomerId: this.state.sessionUser.customerId });
                break;
            default:
                this.setState({ filterCustomerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), });
                break;
        }
    }

    onToolbarPreparing = async (e) => {
        try {
            //let isButtonDisable = !this.state.isGridHasRow;
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.getBookings.bind(this),
                    hint: 'Refresh'
                }
            }
            )
        } catch (e) {
            console.log(e);
        }
    }

    handleClick = (e) => {
        window.location.href = "../booking/greeter/new";
    }

    editTrip = (e, tripData) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/booking/greeter/new',
            state: { data: tripData, sourceUrl: "/booking/view",trip_id: tripData.trip_id }
        });
        window.location.reload(false);
    }

    handleCancelTripModal = async (e, data) => {
        e.preventDefault();
        this.setState({
            cancelModalIsOpen: true,
            cancelTripId: data.trip_id,
        });        
    }

    handleCompleteTripModal = async (e, data) => {
        e.preventDefault();
        this.setState({
            completeModalIsOpen: true,
            completeTripId: data.trip_id,
        });        
    }

    closeCancelModal = (e) =>{
        this.setState({
            cancelModalIsOpen: false,
            cancelTripId: 0,
            cancelReason:"",
        });
    }

    closeCompleteModal = (e) =>{
        this.setState({
            completeModalIsOpen: false,
            completeTripId: 0,
            completeReason:"",
        });
    }

    handleCancelValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.cancelReason = "";
            if (this.state.cancelReason.trim() === '') {
                formIsValid = false;
                errors.cancelReason = "Please enter cancel reason.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCancelValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleCompleteValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.completeReason = "";
            if (this.state.completeReason.trim() === '') {
                formIsValid = false;
                errors.completeReason = "Please enter complete reason.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCompleteValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleCancelTrip = async (e) => {
        e.preventDefault();
        try {
            if (this.handleCancelValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.trip_id = parseInt(this.state.cancelTripId);
                newObject.user_id = parseInt(this.state.sessionUser.userId);
                newObject.isapproved = null;
                newObject.isvoid = true;
                newObject.voidreason = this.state.cancelReason;
                newObject.status_time = currentTime;
                newObject.status_Id = parseInt(TripStatusType.Voided);
                const requestParams = getRequestParams('POST', newObject);                
                const response = await fetch(process.env.REACT_APP_API_BASE_URL +  'booking/job/update-status', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.resultMessage,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeCancelModal();
                            setTimeout(() => this.getBookings(), 500);
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false });
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCancelTrip function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleRecallTrip = async (e, tripdata) => {
        e.preventDefault();
        try {            
            this.setState({ isSubmited: true });
            var newObject = {};
            newObject.trip_id = parseInt(tripdata.trip_id);
            newObject.user_id = parseInt(this.state.sessionUser.userId);
            newObject.isapproved = null;
            newObject.isvoid = false;
            newObject.voidreason = "";
            newObject.status_time = currentTime;
            newObject.status_Id = parseInt(TripStatusType.Open);
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/job/update-status', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.resultMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.closeCancelModal();
                        setTimeout(() => this.getBookings(), 500);
                    }
                    this.setState({ isSubmited: false })
                });
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: { width: "310px" },
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeButton: false
                });
                this.setState({ isSubmited: false });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleRecallTrip function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleCompleteTrip = async (e) => {
        e.preventDefault();
        try {
            if (this.handleCompleteValidation()) {
                debugger;
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.trip_id = parseInt(this.state.completeTripId);
                newObject.user_id = parseInt(this.state.sessionUser.userId);
                newObject.isapproved = null;
                newObject.isvoid = true;
                newObject.voidreason = this.state.completeReason;
                newObject.status_time = currentTime;
                newObject.status_Id = parseInt(TripStatusType.TripComplete);
                const requestParams = getRequestParams('POST', newObject);                
                const response = await fetch(process.env.REACT_APP_API_BASE_URL +  'booking/job/update-status', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.resultMessage,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeCompleteModal();
                            setTimeout(() => this.getBookings(), 500);
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false });
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCompleteTrip function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                {/* {
                    (data.data.trip_status_id === parseInt(TripStatusType.TripComplete)) ||
                        (data.data.trip_status_id === parseInt(TripStatusType.Voided)) ?
                        <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editTrip(e, data.data) }} ></a> :
                        <span>&nbsp;&nbsp;</span>
                }&nbsp;&nbsp; */}
                {
                    (data.data.trip_status_id === parseInt(TripStatusType.Open)) ?
                        <a href="#" className="fas fa-check" title="Complete Booking" onClick={(e) => { this.handleCompleteTripModal(e, data.data) }} ></a> 
                        : <span className="fas fa-check" style={{opacity: "0.2" }} title={data.data.trip_status_name}></span>                        
                }&nbsp;&nbsp;
                {
                    (data.data.trip_status_id === parseInt(TripStatusType.Open)) ?
                        <a href="#" className="fas fa-times" title="Cancel" onClick={(e) => { this.handleCancelTripModal(e, data.data) }} ></a>
                        : <span className="fas fa-times" style={{opacity: "0.2" }} title={data.data.trip_status_name}></span>
                }&nbsp;&nbsp;
                {
                    (data.data.trip_status_id === parseInt(TripStatusType.Voided)) ?
                        <a href="#" className="fas fa-undo-alt" title="Recall" onClick={(e) => { this.handleRecallTrip(e, data.data) }} ></a>
                        : <span className="fas fa-undo-alt" style={{opacity: "0.2" }} title={data.data.trip_status_name}></span>
                }&nbsp;&nbsp;
            </div>;
        }

        const renderTripGridCell = (data) => {
            return <div>
                <a href="#" title={"Edit booking " + data.data.trip_id} onClick={(e) => { this.editTrip(e, data.data) }} ><span className="rideTextColor">{data.data.trip_id}</span></a>&nbsp;&nbsp;              
            </div>;
        }

        const renderFlightCell = (data) => {            
            return <div>
                <img src={data.data.airline_icon} width="20" />&nbsp;{data.data.airline_info}
            </div>;
        }

        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Booking</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                <button type='submit' className='btn btn-primary btn-sm' onClick={this.handleClick}><span><i className="fas fa-plus"></i> New Booking</span>
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.searchFromDate}
                                            value={this.state.searchFromDate}
                                            onValueChanged={this.handleSearchFromDateChange}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.searchToDate}
                                            value={this.state.searchToDate}
                                            onValueChanged={this.handleSearchToDateChange}
                                            width="100%"
                                            acceptCustomValue={false} />
                                    </div>
                                </div>
                                {
                                    this.state.showCustomer === true ?                                    
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <div className="form-group">
                                                    <label>Select Customer</label>
                                                    <SelectBox
                                                        dataSource={this.state.customers.length > 0 ? this.state.customers : []}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select Customer"
                                                        name="customerId"
                                                        defaultValue={0}
                                                        value={this.state.filterCustomerId}
                                                        displayExpr='name'
                                                        valueExpr='id'
                                                        onSelectionChanged={this.handleCustomerChange}
                                                        showClearButton={true}
                                                        disabled={false}
                                                        searchEnabled={true}
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                }
                                {
                                    this.state.showGreeter === true ?

                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div className="form-group">
                                                <label>Select Greeter&nbsp;</label>
                                                <SelectBox
                                                    dataSource={this.state.serviceGreeters.length > 0 ? this.state.serviceGreeters : []}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Greeter"
                                                    name="filterGreeterId"
                                                    defaultValue={0}
                                                    value={this.state.filterGreeterId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleFilterGreeterChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                    width="100%"
                                                />
                                            </div>
                                        </div> : null
                                }
                                <div className="form-group">
                                    <div>&nbsp;</div>
                                    <button type="submit" className="btn btn-dark btn-sm" onClick={this.handleBookingSearch} data-slide="true">
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i></span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-default btn-sm" onClick={this.handleReset} data-slide="true">
                                        <span title="Reset"><i className="fas fa-eraser" title="Reset"></i></span>
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm mr-1" onClick={this.handleAllJobs} data-slide="true">
                                        <span title="All"><i className="fas fa-tasks"></i> All</span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm mr-1" onClick={this.handlePendingJobs} data-slide="true">
                                        <span title="Pending"><i className="fas fa-check-double"></i> Pending</span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm" onClick={this.handleCompletedJobs} data-slide="true">
                                        <span title="Completed"><i className="fas fa-check"></i> Completed</span>
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-link btn-sm" onClick={this.handleCancelledJobs} data-slide="true">
                                        <span title="Cancelled"><i className="fas fa-ban"></i> Cancelled</span>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className='card card-grid'>
                                <div className='card-body'>
                                    <DataGrid
                                        ref={(ref) => this.dataGrid = ref}
                                        dataSource={this.state.bookingSource}
                                        keyExpr="trip_id"
                                        id="dispatch-greeter-container"
                                        name="gridGreeterContainer"
                                        width="100%"
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.handleMultipleRideSelectionChanged}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"none"}
                                        />
                                        {/* <Column dataField="booking_id" caption="Booking No" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} /> */}
                                        <Column dataField="trip_id" caption="Trip No" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} alignment="left" cellRender={renderTripGridCell} />
                                        <Column dataField="pickup_address" caption="Airport" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                        <Column dataField="pickup_time" caption="Pick-up Date Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} dataType="datetime" format="MM/dd/yyyy HH:mm" />
                                        <Column dataField="airline_info" caption="Flight" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderFlightCell} />
                                        {/* <Column dataField="terminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} /> */}
                                        <Column dataField="pax_name" caption="Pax Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="no_of_pax" caption="Pax Count" allowSearch={true} allowFiltering={false} allowSorting={true} width={75} alignment="left" />
                                        <Column dataField="mobile" caption="Mobile No" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} alignment="left" />
                                        <Column dataField="email" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />                                        
                                        {/* <Column dataField="greeter_name" caption="Greeter Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                                        <Column dataField="customer_name" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                        <Column dataField="dropoff_address" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={350} />                                        
                                        {/* <Column dataField="airline_code" caption="Airline" allowSearch={true} allowFiltering={false} allowSorting={true} width={60} /> */}                                        
                                        <Column dataField="driver_info" caption="Driver" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                        <Column dataField="trip_status_name" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                        <Column dataField="trip_id" width={85} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        {/* <Template name="multipleGreeter" render={this.toolbarItemRender} /> */}
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="Bookings" />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.cancelModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeCancelModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCancelTrip} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{"Cancel Boooking [" + this.state.cancelTripId + "]"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Cancellation Reason <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="cancelReason"
                                             placeholder="Enter cancel reason" value={this.state.cancelReason} 
                                        title="Cancel Reason" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["cancelReason"].length > 0 && <span className="error invalid-feedback">{this.state.errors["cancelReason"]}</span>}
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-dark btn-sm" onClick={this.handleCancelTrip} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeCancelModal} title="Close"><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.completeModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeCompleteModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCompleteTrip} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{"Complete Boooking [" + this.state.completeTripId + "]"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Complete Reason <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="completeReason"
                                             placeholder="Enter Complete reason" value={this.state.completeReason} 
                                        title="Complete Reason" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["completeReason"].length > 0 && <span className="error invalid-feedback">{this.state.errors["completeReason"]}</span>}
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-dark btn-sm" onClick={this.handleCompleteTrip} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeCompleteModal} title="Close"><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}