import React, { Component, Fragment } from 'react';
import { getRequestParams } from '../../helpers/globalfunctions.js';

export class ShowGreeterBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookingSource: this.props.bookingSource != null ? this.props.bookingSource : [],
        };
    }

    async componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <div style={{ textAlign: "center", margin: "0", padding: "0" }}>
                    <div className="banner-greeting">Welcome</div>
                    <div className="banner-pax-details">
                        <div className="banner-pax">{this.state.bookingSource.full_name}</div>
                    </div>
                    <div className="banner-logo">
                        <img src="../../dist/img/greeterbanner/logo-airporter-greeter-com.png" alt="Airport Greeter" width="160px" />
                    </div>
                </div>
            </Fragment>
        );
    }
}