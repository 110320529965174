import React, { Component, Fragment } from 'react';
import { getRequestParams } from '../../helpers/globalfunctions.js';

export class BlnkGreeterBannerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingSource: this.props.bookingSource != null ? this.props.bookingSource : [],
        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                <div className="blnk-body">
                    <div className="blnk-pax-details">
                        <div className="blnk-greeter-banner-top"> <img src="../../dist/img/greeterbanner/greeter_banner_top.png" /></div>
                        <div className="blnk-pax">{this.state.bookingSource.full_name}</div>
                        <div className="blnk-greeting">Welcome to NYC, NY</div>
                        <div className="blnk-greeter-banner-btm"> <img src="../../dist/img/greeterbanner/greeter_banner_bottom.png" /></div>
                    </div>
                    <div className="blnk-logo">
                        <img src="../../dist/img/greeterbanner/logo-nycunited-limo.png" />
                    </div>
                </div>
            </Fragment>
        );
    }
}