import React, { Component, Fragment } from 'react';
import * as XLSX from 'xlsx'
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
}
    from 'devextreme-react/data-grid';
import { DateFormat } from '../../helpers/fixcodes.js';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';

const position = { of: '#historydiv' };
const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const basedate = new Date(1899, 11, 30, 0, 0, 0);
const dnthresh = basedate.getTime() + (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
const day_ms = 24 * 60 * 60 * 1000;
const days_1462_ms = 1462 * day_ms;
let currentTime = sessionStorage.getItem("session_branchTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_branchTime"));

export class UploadGreeterReservations extends Component {
    static displayName = UploadGreeterReservations.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            attachment: null,
            excelFile: "",
            file_name: "",
            excelData: null,
            excelJsonData: "",
            isReservationDisabled: true,
            serviceproviders: [],
            serviceProviderId: 0,
            customers: [],
            customerId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            no_of_bookings: 0,
            errors: {
                serviceProviderId:"",
                attachment: '',
                excelFileError: "",
                customerId: 0,
            },
        }

        this.handleFormValidation = this.handleFormValidation.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSaveReservations = this.handleSaveReservations.bind(this);
        this.handleClearControls = this.handleClearControls.bind(this);
    }

    async componentDidMount() {
        //console.log(this.state.sessionUser);
        await this.getServiceProviders();
    }

    async getServiceProviders() {
        try {
            var request = {};
            request.service_provider_Id = parseInt(sessionStorage.getItem('session_accountId'));
            request.customer_id = parseInt(0);
            request.customer_type = parseInt(1);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/serviceprovider/dropdown', requestParams);
            const data = await response.json();            
            this.setState({
                serviceproviders: data.customerDropdownList,
                serviceProviderId: parseInt(this.state.sessionUser.accountId),
            });

            if (this.state.sessionUser.accountId > 1){
                setTimeout(() => this.getCustomers(this.state.sessionUser.accountId), 500);
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceProviders function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    async getCustomers() {
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleClearControls = (e) => {
        window.location.href = "../upload/GreeterReservations";
        // this.setState({
        //     isSubmited: false,
        //     attachment: null,
        //     excelFile: "",
        //     excelData: null,
        //     excelJsonData: "",
        //     isReservationDisabled: true,
        //     customerId: 0,
        //     errors: {
        //         attachment: '',
        //         excelFileError: "",
        //         customerId: 0,
        //     },
        // });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'attachment':
                errors.attachment = "";
                if (event.target.files.length === 0) {
                    this.state.errors["attachment"] = null;
                    //this.setState({ attachment: null });
                    errors.attachment = "Please select attachment.";
                } else {
                    // this.setState({
                    //     errors:{
                    //         "attachment":"",
                    //     }
                    // });
                    this.state.errors["attachment"] = "";
                    this.setState({ attachment: event.target.files[0] });
                }
                break;
            // case 'serviceproviderId':
            //     errors.serviceProviderId = 0;
            //     if (this.state.serviceProviderId === 0) {
            //         this.setState({ serviceProviderId: null });
            //         errors.serviceProviderId = "Select service provider";
            //     }
            //     else {
            //         this.state.errors["serviceProviderId"] = 0;
            //         this.setState({ serviceProviderId: parseInt(this.state.sessionUser.accountId) });
            //     }
            //     break;
            // case 'customerId':
            //     errors.customerId = 0;
            //     if (this.state.customerId === 0) {
            //         this.setState({ customerId: 0 });
            //         errors.customerId = "Select service customer";
            //     }
            //     else {
            //         this.state.errors["customerId"] = 0;
            //         this.setState({ customerId: event.target.value });
            //     }
            //     break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }

    handleFormValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.attachment = '';
        if (this.state.attachment === null) {
            formIsValid = false;
            errors.attachment = "Please select attachment.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleFile = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    this.setState({
                        excelFile: e.target.result,
                        attachment: e.target.result
                    });
                }
            }
            else {
                // setExcelFileError('Please select only excel file types');
                this.setState({
                    excelFile: null,
                    attachment: ""
                });
            }
        }
        else {
            //console.log('plz select your file');
        }
    }

    datenum = (v, date1904) => {
        let epoch = v.getTime();
        if (date1904) {
            epoch -= days_1462_ms;
        }
        return (epoch - dnthresh) / day_ms;
    }

    fixImportedDate = (date, is_date1904) => {
        // Convert JS Date back to Excel date code and parse them using SSF module.        
        const parsed = XLSX.SSF.parse_date_code(this.datenum(date, false), { date1904: is_date1904 });
        //console.log("parsed: " + parsed);
        return `${parsed.y}-${parsed.m}-${parsed.d}`;
        // or
        //return parsed;
        // or if you want to stick to JS Date,
        //return new Date(parsed.y, parsed.m, parsed.d, parsed.H, parsed.M, parsed.S);
    }

    
    // submit function
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.handleFormValidation()) {
            if (this.state.excelFile !== null) {
                debugger;
                const workbook = XLSX.read(this.state.excelFile, { type: 'buffer', cellDates: true, });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                
                const data = XLSX.utils.sheet_to_json(worksheet);
                //const is_date1904 = workbook.Workbook.WBProps.date1904;

                // const fixed = data.map((arr) => data.map((v) => {
                //     if (v instanceof Date) {                        
                //         //return this.fixImportedDate(v.Date, is_date1904);
                //         let excelDate = new Date(new Date(v.Date).setDate(v.Date.getDate() + 1));
                //         //console.log("ExcelDate 1:" + excelDate);
                //         // //return getJsDateFromExcel(excelDate);
                //         // //return this.fixImportedDate(excelDate, is_date1904);
                //         return excelDate;
                //     } else {
                //         //return v.Date;
                //         //return this.fixImportedDate(v.Date, is_date1904);
                //         let excelDate = new Date(new Date(v.Date).setDate(v.Date.getDate() + 1));
                //         // console.log("ExcelDate 2:" + excelDate);
                //         // //return getJsDateFromExcel(excelDate);
                //         // //return this.fixImportedDate(excelDate, is_date1904);
                //         return excelDate;
                //     }
                // }));
                //console.log(data);

                if (data != null) {
                    debugger;
                    let jsonExcelData = JSON.stringify(data);                    
                    // console.log(data);
                    //console.log(JSON.stringify(jsonExcelData));
                    this.setState({
                        excelData: data,
                        excelJsonData: jsonExcelData,
                        isReservationDisabled: false,
                        no_of_bookings: parseInt(data.length),
                    });
                }
            }
            else {
                this.setState({
                    excelData: null
                });
            }
        }
    }

    handleServiceProviderChange = (selectedOption) => {
        if(selectedOption.selectedItem != null) {
            if (selectedOption.selectedItem.id > 0){
                this.getCustomers(selectedOption.selectedItem.id);
            }
        }
        this.setState({ serviceProviderId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleCustomerChange = (selectedOption) => {        
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = "";

            // if (this.state.serviceProviderId === 0) {
            //     formIsValid = false;
            //     errors.serviceProviderId = "Please select service provider";
            // }
            if (this.state.customerId === 0) {
                formIsValid = false;
                errors.customerId = "Please select customer";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleSaveReservations = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({
                isSubmited: true
            })
            debugger;
            if (this.state.excelData != null) {
                var request = {};                
                request.user_id = parseInt(sessionStorage.getItem('session_userId'));;
                request.service_provider_id = parseInt(this.state.serviceProviderId);
                request.branch_id = parseInt(sessionStorage.getItem('session_branchId'));
                request.customer_id = this.state.customerId;
                request.jsondata = this.state.excelJsonData;
                request.file_name = "";
                request.no_of_bookings = parseInt(this.state.no_of_bookings);
                request.uploaded_date = formatDate(currentTime, "MM/dd/yyyy hh:mm:ss");
                const requestParams = getRequestParams("POST", request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'booking/greeter/save-upload', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'info' : 'error',
                    title: data.isSuccess ? '<small>Reservation Upload</small>' : '<small>Oops...</small>',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        window.location.href = "../upload/GreeterReservations";
                    } else {
                        this.setState({ isSubmited: false })
                    }
                });
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Upload Greeter Bookings</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>Select Service Provider</label>
                                    <SelectBox
                                        dataSource={this.state.serviceproviders.length > 0 ? this.state.serviceproviders : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Service Provider"
                                        name="serviceProviderId"
                                        defaultValue={0}
                                        value={this.state.serviceProviderId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleServiceProviderChange}
                                        showClearButton={true}
                                        disabled={this.state.serviceProviderId > 1}
                                        searchEnabled={true}
                                        width="100%"
                                    />
                                    {this.state.errors["serviceProviderId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceProviderId"]}</span>}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Select Customer</label>
                                    <SelectBox
                                        dataSource={this.state.customers.length > 0 ? this.state.customers : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Customer"
                                        name="customerId"
                                        defaultValue={0}
                                        value={this.state.customerId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleCustomerChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                        width="100%"
                                    />
                                    {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>Select File</label>
                                    {/* <form className='form-group' autoComplete="off" onSubmit={this.handleSubmit}>
                                            <input type='file' className='form-control' onChange={this.handleFile} required></input>
                                            {this.state.errors["attachment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["attachment"]}</span>}
                                    </form> */}
                                    <form className='form-group' autoComplete="off" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <input type='file' className='form-control' onChange={this.handleFile} required></input>
                                                {this.state.errors["attachment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["attachment"]}</span>}
                                            </div>
                                            <div className="col-lg-6">
                                                <button type='submit' className='btn btn-dark btn-sm'>Submit</button>&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>&nbsp;</label><br />
                                    {/* <button type='submit' className='btn btn-dark btn-sm'><i className="fas fa-check" title="Submit"></i> Submit</button>&nbsp;&nbsp; */}
                                    <button type='submit' className='btn btn-default btn-sm' disabled={this.state.isReservationDisabled} onClick={this.handleSaveReservations}>
                                        {this.state.isSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span><i className="fas fa-check"></i> Save </span>}
                                    </button>&nbsp;
                                    <button type='submit' className='btn btn-default btn-sm' onClick={this.handleClearControls}><i className="fas fa-eraser"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.excelData}
                                    //keyExpr="KOIID"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="RefNo" caption="Ref No" width={160} />
                                    <Column dataField="Date" caption="Date" width={120} format={DateFormat.DateWesternFormat} dataType="datetime" />
                                    <Column dataField="Time" caption="Time" width={120} format={DateFormat.Time24Format} dataType="datetime" />
                                    <Column dataField="ARDP" caption="ARDP" width={90} />
                                    <Column dataField="PickupLocation" caption="Pick-up Location" width={150} />
                                    <Column dataField="DropoffLocation" caption="Drop Off Location" />
                                    <Column dataField="Flight" caption="Flight" />
                                    <Column dataField="CarType" caption="Car Type" />
                                    <Column dataField="Pax" caption="Pax" width={150} />
                                    <Column dataField="Passenger" caption="Passenger" width={150} />
                                    <Column dataField="Supplier" caption="Supplier" width={150} />
                                    <Column dataField="Driver" caption="Driver" width={150} />
                                    <Column dataField="Phone" caption="Phone" width={150} />
                                    <Column dataField="Vip" caption="Vip" width={150} />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={false} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="UploadedFile.xlsx" />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}