import React, { Component, Fragment } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import { toast } from 'react-toastify'; //npm i react-toastify --save
import 'react-toastify/dist/ReactToastify.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { vMinLength, vMinUpperCaseLetter, vMinLowerCaseLetter, vMinNumber, vMinSpecialCharacter, vIsPasswordComplexity } from '../../helpers/globalvalidations.js';

const customStyles = {
    content: {
        width: '25%',
    }
};
const position = { of: '#historydiv' };
export class ChangePasswordComponent extends Component {
    static displayName = ChangePasswordComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            oldtype: 'password',
            accountId: 0,
            type: 'password',
            retype: 'password',
            userId: 0,
            userName: '',
            oldPassword: '',
            newPassword: '',
            oldRePassword: '',
            newRePassword: '',
            reTypePassword: '',
            vIsPasswordComplexity: vIsPasswordComplexity,
            vMinLength: vMinLength,
            vMinUpperCaseLetter: vMinUpperCaseLetter,
            vMinLowerCaseLetter: vMinLowerCaseLetter,
            vMinNumber: vMinNumber,
            vMinSpecialCharacter: vMinSpecialCharacter,
            logged_user: true,
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            errors: {
                errOldPassword: '',
                errNewPassword: '',
                errReTypePassword: '',
            }
        }
    }

    showModal = (data) => {
        this.setState({ isOpenModal: true })
        if (data == null) {
            this.setState({ accountId: this.state.sessionUser.accountId, userId: this.state.sessionUser.id, userName: this.state.sessionUser.userName, logged_user: true })
        }
        else {
            this.setState({ accountId: data.accountId, userId: data.id, userName: data.userName, logged_user: false })
        }
    }
    closeModal = () => {
        this.setState({
            isDisabled: true,
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            type: 'password',
            retype: 'password',
            oldPassword: '',
            newPassword: '',
            reTypePassword: '',
            oldRePassword: '',
            newRePassword: '',

            vIsPasswordComplexity: true,
            vMinLength: vMinLength,
            vMinUpperCaseLetter: vMinUpperCaseLetter,
            vMinLowerCaseLetter: vMinLowerCaseLetter,
            vMinNumber: vMinNumber,
            vMinSpecialCharacter: vMinSpecialCharacter,
            errors: {
                errOldPassword: '',
                errNewPassword: '',
                errReTypePassword: '',
            }
        });
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getValidationRules();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    getValidationRules = async () => {
        try {
            let accountId = this.state.sessionUser.accountId;
            const requestParams = getRequestParams('GET');
            this.setState({
                loadPanelVisible: true
            });
            // const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'PasswordPolicy/validation-rule', requestParams);
            // const data = await response.json();

            // if (data !== null && data.payload != null) {
            //     this.setState({
            //         vIsPasswordComplexity: true,
            //         vMinLength: data.payload.minLength,
            //         vMinUpperCaseLetter: data.payload.minUpperCaseLetter,
            //         vMinLowerCaseLetter: data.payload.minLowerCaseLetter,
            //         vMinNumber: data.payload.minNumber,
            //         vMinSpecialCharacter: data.payload.minSpecialCharacter
            //     });
            // }

                 this.setState({
                    vIsPasswordComplexity: true,
                    vMinLength: this.state.vMinLength,
                    vMinUpperCaseLetter: this.state.vMinUpperCaseLetter,
                    vMinLowerCaseLetter: this.state.vMinLowerCaseLetter,
                    vMinNumber: this.state.vMinNumber,
                    vMinSpecialCharacter: this.state.vMinSpecialCharacter
                });
        } catch (e) {
            console.log(e);
        }
    }

    handleOnCloseModal = async () => {
        this.setState({
            type: 'password',
            retype: 'password',
            oldPassword: '',
            newPassword: '',
            reTypePassword: '',
            oldRePassword: '',
            newRePassword: '',
            vIsPasswordComplexity: false,
            vMinLength: 0,
            vMinUpperCaseLetter: 0,
            vMinLowerCaseLetter: 0,
            vMinNumber: 0,
            vMinSpecialCharacter: 0,
            errors: {
                errOldPassword: '',
                errNewPassword: '',
                errReTypePassword: '',
            }
        });
    }

    oldPwdShowHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            oldtype: this.state.oldtype === 'input' ? 'password' : 'input',
            errOldPassword: '',
        })
    }

    showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input',
            errNewPassword: '',
            // errReTypePassword: '',
        })
    }
    pwdShowHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            //oldtype: this.state.oldtype === 'input' ? 'password' : 'input',
            //type: this.state.type === 'input' ? 'password' : 'input',
            retype: this.state.retype === 'input' ? 'password' : 'input',
            errReTypePassword: '',
            //errNewPassword: '',
            //errReTypePassword: '',
        })
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "newPassword" || name === "reTypePassword") {
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");

            const renumbersymbolexp = /[^0-9]/g;
            const lowercaseexp = /[^a-z]/g;
            const uppercaseexp = /[^A-Z]/g;
            const specialcharacters = /[^!@#$%^&*]/g;

            var IsPwdnumbersymbol = value.replace(renumbersymbolexp, "").length;
            var IsLowercase = value.replace(lowercaseexp, "").length;
            var IsUppercase = value.replace(uppercaseexp, "").length;
            var IsSpecialCharacters = value.replace(specialcharacters, "").length;

            if (elementpwdlength !== null) {
                if (value.length >= this.state.vMinLength) {
                    elementpwdlength.classList.remove("text-muted");
                    elementpwdlength.classList.add("text-success");
                }
                else {
                    elementpwdlength.classList.remove("text-success");
                    elementpwdlength.classList.add("text-muted");
                }
            }

            if (elementpwdnumbersymbol !== null) {
                if (IsPwdnumbersymbol >= this.state.vMinNumber) {
                    elementpwdnumbersymbol.classList.remove("text-muted");
                    elementpwdnumbersymbol.classList.add("text-success");
                }
                else {
                    elementpwdnumbersymbol.classList.remove("text-success");
                    elementpwdnumbersymbol.classList.add("text-muted");
                }
            }

            if (elementpwdlowercase !== null) {
                if (IsLowercase >= this.state.vMinLowerCaseLetter) {
                    elementpwdlowercase.classList.remove("text-muted");
                    elementpwdlowercase.classList.add("text-success");
                }
                else {
                    elementpwdlowercase.classList.remove("text-success");
                    elementpwdlowercase.classList.add("text-muted");
                }
            }

            if (elementpwduppercase !== null) {
                if (IsUppercase >= this.state.vMinUpperCaseLetter) {
                    elementpwduppercase.classList.remove("text-muted");
                    elementpwduppercase.classList.add("text-success");
                }
                else {
                    elementpwduppercase.classList.remove("text-success");
                    elementpwduppercase.classList.add("text-muted");
                }
            }

            if (elementspecialcharacters !== null) {
                if (IsSpecialCharacters >= this.state.vMinSpecialCharacter) {
                    elementspecialcharacters.classList.remove("text-muted");
                    elementspecialcharacters.classList.add("text-success");
                }
                else {
                    elementspecialcharacters.classList.remove("text-success");
                    elementspecialcharacters.classList.add("text-muted");
                }
            }

            if (value === "") {
                if (elementpwdlength !== null) {
                    elementpwdlength.classList.remove("text-success");
                    elementpwdlength.classList.add("text-muted");
                }

                if (elementpwdnumbersymbol !== null) {
                    elementpwdnumbersymbol.classList.remove("text-success");
                    elementpwdnumbersymbol.classList.add("text-muted");
                }

                if (elementpwdlowercase !== null) {
                    elementpwdlowercase.classList.remove("text-success");
                    elementpwdlowercase.classList.add("text-muted");
                }

                if (elementpwduppercase !== null) {
                    elementpwduppercase.classList.remove("text-success");
                    elementpwduppercase.classList.add("text-muted");
                }

                if (elementspecialcharacters !== null) {
                    elementspecialcharacters.classList.remove("text-success");
                    elementspecialcharacters.classList.add("text-muted");
                }
            }

            if (this.state.vIsPasswordComplexity === true) {
                if (value.length >= this.state.vMinLength && IsPwdnumbersymbol >= this.state.vMinNumber && IsLowercase >= this.state.vMinLowerCaseLetter && IsUppercase >= this.state.vMinUpperCaseLetter && IsSpecialCharacters >= this.state.vMinSpecialCharacter) {
                    this.setState({ isDisabled: false });
                } else {
                    this.setState({
                        isDisabled: true,
                        errOldPassword: '',
                        errNewPassword: '',
                        errReTypePassword: '',
                    });
                }
            } else {
                this.setState({ isDisabled: false });
            }
        }

        this.setState({
            [name]: value
        });
    }
    handleValidation = () => {        
        let errors = this.state.errors;
        let formIsValid = true;

        errors.errOldPassword = "";
        errors.errNewPassword = "";
        errors.errReTypePassword = "";

        if (this.state.logged_user === true) {
            if (this.state.oldPassword.trim() === '') {
                formIsValid = false;
                errors.errOldPassword = "Please enter old password.";
            }
        }

        if (this.state.newPassword.trim() === '') {
            formIsValid = false;
            errors.errNewPassword = "Please enter new password.";
        }

        if (this.state.reTypePassword.trim() === '') {
            formIsValid = false;
            errors.errReTypePassword = "Please enter re-type password.";
        }

        if (this.state.logged_user === true) {
            if (this.state.oldPassword.trim() === this.state.newPassword.trim()) {
                formIsValid = false;
                errors.errReTypePassword = "Old and new password must not be same.";
            }
        }

        if (this.state.newPassword.trim() !== this.state.reTypePassword.trim()) {
            formIsValid = false;
            errors.errReTypePassword = "Password does not match.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault();        
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var changePasswordRequest = {};
            changePasswordRequest.service_provider_id = parseInt(this.state.sessionUser.accountId);
            changePasswordRequest.user_name = this.state.userName;
            changePasswordRequest.old_passcode = this.state.oldPassword;
            changePasswordRequest.new_passcode = this.state.newPassword.trim();
            changePasswordRequest.logged_user = this.state.logged_user;
            
            const requestParams = getRequestParams('POST', changePasswordRequest);
            try {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'verification/change-password', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    toast.success(data.resultMessage + '!', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: 'toast-message'
                    });

                    this.closeModal();
                    if (this.state.sessionUser.userName === this.state.userName) {
                        sessionStorage.clear();
                        window.location.href = "/";
                    }
                }
                else 
                {
                    toast.danger(data.resultMessage + '!', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: 'toast-message'
                    }); //danger//info//warning
                }
                // Swal.fire({
                //     icon: data.isSuccess ? 'success' : 'error',
                //     title: data.isSuccess ? 'Success' : 'Oops...',
                //     text: data.resultMessage,
                //     confirmButtonText: data.isSuccess ? 'Done' : 'Cancel',
                // }).then((result) => {
                //     /* Read more about isConfirmed, isDenied below */
                //     if (data.isSuccess) {
                //         if (result.isConfirmed) {
                //             this.closeModal();
                //             e.preventDefault();
                //             if (this.state.sessionUser.userName === this.state.userName) {
                //                 sessionStorage.clear();
                //                 window.location.href = "/";
                //             }
                //         }
                //     } else {
                //         this.setState({ isSubmited: false })
                //     }
                // })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }
    render() {
        return (
            <Fragment>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal change-password"}
                    shouldCloseOnOverlayClick={false}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Change Password</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <p className="login-box-msg">Forgot your password? Here you can easily retrieve a new password.</p> */}
                            <p className="login-box-msg">You are going to change the password for <i><strong className="text-danger">{this.state.userName}</strong></i> as user name.</p>
                            <div className="row m-0">
                                {
                                    this.state.logged_user === true ?
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Old Password <span className="text-danger">*</span></label><label className="mb-1 float-right"><a href="#" onClick={this.oldPwdShowHide}>{this.state.oldtype === 'input' ? 'Hide Password' : 'Show Password'}</a></label>
                                                <input type={this.state.oldtype} className="form-control form-control-sm" id="oldPassword" name="oldPassword" autoComplete="new-password" title="Old Password" placeholder="Enter old password" value={this.state.oldPassword} onChange={this.handleInputChange} />
                                                {this.state.errors["errOldPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["errOldPassword"]}</span>}
                                            </div>
                                        </div> : null
                                }
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>New Password <span className="text-danger">*</span></label><label className="mb-1 float-right"><a href="#" onClick={this.showHide}>{this.state.type === 'input' ? 'Hide Password' : 'Show Password'}</a></label>
                                        <input type={this.state.type} className="form-control form-control-sm" id="newPassword" name="newPassword" autoComplete="new-password" title="New Password" placeholder="Enter new password" value={this.state.newPassword} onChange={this.handleInputChange} />
                                        {this.state.errors["errNewPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["errNewPassword"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Re-type New Password <span className="text-danger">*</span></label><label className="mb-1 float-right"><a href="#" onClick={this.pwdShowHide}>{this.state.retype === 'input' ? 'Hide Password' : 'Show Password'}</a></label>
                                        <input type={this.state.retype} className="form-control form-control-sm" id="reTypePassword" name="reTypePassword" autoComplete="new-password" title="Re-type New Password" placeholder="Re-type new password" value={this.state.reTypePassword} onChange={this.handleInputChange} />
                                        {this.state.errors["errReTypePassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["errReTypePassword"]}</span>}
                                    </div>
                                </div>
                            </div>
                            {(this.state.vIsPasswordComplexity === true) && <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <p className="mb-0"><small id="pwdlength" className="text-muted"><i className="fas fa-check-circle"></i> At least {this.state.vMinLength} characters long</small></p>
                                        <p className="mb-0"><small id="pwdlowercase" className="text-muted"><i className="fas fa-check-circle"></i> At least {this.state.vMinLowerCaseLetter} minimum lowercase character</small></p>
                                        <p className="mb-0"><small id="pwduppercase" className="text-muted"><i className="fas fa-check-circle"></i> At least {this.state.vMinUpperCaseLetter} minimum uppercase character</small></p>
                                        <p className="mb-0"><small id="pwdnumbersymbol" className="text-muted"><i className="fas fa-check-circle"></i> At least {this.state.vMinNumber} minimum number</small></p>
                                        <p className="mb-0"><small id="specialcharacters" className="text-muted"><i className="fas fa-check-circle"></i> At least {this.state.vMinSpecialCharacter} special character</small></p>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <div className="modal-footer text-center">
                            <button type="submit" className="btn btn-primary" disabled={this.state.vIsPasswordComplexity === true ? this.state.isDisabled : false} title="Update"><i className="fas fa-sign-in-alt"></i> Change Password</button>
                            <button type="button" className="btn btn-custom-gray" onClick={this.closeModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}