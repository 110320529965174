import React, { Component, Fragment } from 'react';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import Highcharts from 'highcharts';
const position = { of: '#historydiv' };

export class DashboardComponent extends Component {
    static displayName = DashboardComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            datasource: null,
            reservationdatasource: null,
            loadPanelVisible: false,
            totalAirport: 0,
            totalRides: 0,
            totalServiceProviders: 0,
            totalCompletedRides: 0,
            totalHotelBookings: 0,
            totalDepartureCount: 0,
            totalArrivalCount: 0,
            totalNoshowCount: 0,
            totalDelayCount: 0,
            totalCancelledCount: 0,
            totalCrewRides: 0,
            scheduleVsActualData: [],
            hourlyData: [],
            airportList: [],
            reservationTitle: "Crew Transfers for Today",
        }
    }

    async componentDidMount() {
        //await this.getCompleteDashboard();
        // await this.getDashboardCount();
        // await this.getDashboardScheduleVsActual();
        // await this.getDashboardHourlyData();
        //await this.getReservations();

    }
    // getCompleteDashboard = async () => {
    //     try {
    //         this.setState({ loadPanelVisible: true });
    //         let airlineCode = sessionStorage.getItem('session_airlineCode');
    //         let userId = parseInt(sessionStorage.getItem('session_userId'));
    //         let userName = sessionStorage.getItem('session_userName');
    //         let accountId = 0;

    //         const dashBoardRequest = {};
    //         dashBoardRequest.AccountId = accountId;
    //         dashBoardRequest.AirlineCode = airlineCode;
    //         dashBoardRequest.UserId = userId;
    //         dashBoardRequest.UserName = userName;
    //         dashBoardRequest.NoOfDays = 10;
    //         const requestParams = getRequestParams('POST', dashBoardRequest);
    //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Dashboard/CompleteDashboard', requestParams);
    //         const data = await response.json();
    //         if (data != null) {
    //             this.setState({
    //                 totalAirport: data.payload[0].serviceAirportCount,
    //                 totalServiceProviders: data.payload[0].serviceAccountCount,
    //                 totalCompletedRides: data.payload[0].totalRidesCompleteCount,
    //                 totalRides: data.payload[0].totalRidesCount,
    //                 totalHotelBookings: data.payload[0].totalHotelBookingCount,
    //                 scheduleVsActualData: data.payload[0].airlineScheduleVsActualData,
    //                 hourlyData: data.payload[0].hourlyRidesModel,
    //                 totalDepartureCount: data.payload[0].totalDepartureCount,
    //                 totalArrivalCount: data.payload[0].totalArrivalCount,
    //                 totalNoshowCount: data.payload[0].totalNoshowCount,
    //                 totalDelayCount: data.payload[0].totalDelayCount,
    //                 totalCancelledCount: data.payload[0].totalCancelledCount,
    //                 totalCrewRides: data.payload[0].totalCrewRides
    //             });
    //         }

    //         this.shceduleVsActualChartRender();
    //         this.hourlyChartRender();
    //         this.setState({ loadPanelVisible: false });
    //     } catch (e) {
    //         //debugger;
    //     }
    // }
    // getDashboardCount = async () => {
    //     try {
    //         this.setState({ loadPanelVisible: true });
    //         let airlineCode = sessionStorage.getItem('session_airlineCode');
    //         let userId = parseInt(sessionStorage.getItem('session_userId'));
    //         let userName = sessionStorage.getItem('session_userName');
    //         let accountId = 0;

    //         const dashBoardRequest = new Object();
    //         dashBoardRequest.AccountId = accountId;
    //         dashBoardRequest.AirlineCode = airlineCode;
    //         dashBoardRequest.UserId = userId;
    //         dashBoardRequest.UserName = userName;
    //         const requestParams = getRequestParams('POST', dashBoardRequest);
    //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Dashboard/AirlineDashBoard', requestParams);
    //         const data = await response.json();

    //         if (data != null) {
    //             this.setState({
    //                 totalAirport: data.payload[0].serviceAirportCount,
    //                 totalServiceProviders: data.payload[0].serviceAccountCount,
    //                 totalCompletedRides: data.payload[0].totalRidesCompleteCount,
    //                 totalRides: data.payload[0].totalRidesCount,
    //             });
    //         }

    //         this.setState({ loadPanelVisible: false });
    //     } catch (e) {
    //         //debugger;
    //     }
    // }
    // getDashboardScheduleVsActual = async () => {
    //     try {
    //         this.setState({ loadPanelVisible: true });
    //         let airlineCode = sessionStorage.getItem('session_airlineCode');
    //         let userId = parseInt(sessionStorage.getItem('session_userId'));
    //         let userName = sessionStorage.getItem('session_userName');

    //         const dashBoardRequest = new Object();
    //         dashBoardRequest.AirlineCode = airlineCode;
    //         dashBoardRequest.UserId = userId;
    //         dashBoardRequest.UserName = userName;
    //         dashBoardRequest.NoOfDays = 10;
    //         const requestParams = getRequestParams('POST', dashBoardRequest);
    //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Dashboard/AirlineScheduleVsActual', requestParams);
    //         const data = await response.json();

    //         if (data != null) {
    //             this.setState({
    //                 scheduleVsActualData: data.payload,
    //             });
    //         }
    //         this.shceduleVsActualChartRender();
    //         this.setState({ loadPanelVisible: false });
    //     } catch (e) {
    //         //debugger;
    //     }
    // }
    // getDashboardHourlyData = async () => {
    //     try {
    //         this.setState({ loadPanelVisible: true });
    //         let airlineCode = sessionStorage.getItem('session_airlineCode');
    //         let userId = parseInt(sessionStorage.getItem('session_userId'));
    //         let userName = sessionStorage.getItem('session_userName');

    //         const dashBoardRequest = new Object();
    //         dashBoardRequest.AirlineCode = airlineCode;
    //         dashBoardRequest.UserId = userId;
    //         dashBoardRequest.UserName = userName;
    //         const requestParams = getRequestParams('POST', dashBoardRequest);
    //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Dashboard/HourlyRides', requestParams);
    //         const data = await response.json();

    //         if (data != null) {
    //             this.setState({
    //                 hourlyData: data.payload,
    //             });
    //         }
    //         this.hourlyChartRender();
    //         this.setState({ loadPanelVisible: false });
    //     } catch (e) {
    //         //debugger;
    //     }
    // }

    handleRowPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.data.rideStatus === "Pending") {
                e.rowElement.style.backgroundColor = '#f2f2f2';
            }
            else if (e.data.rideStatus === "CANCELLED") {
                e.rowElement.style.backgroundColor = '#f2f2f2';
                e.rowElement.style.foregroundColor = '#ffffff';
            }
            else if (e.data.rideStatus === "Driver En Route") {
                e.rowElement.style.backgroundColor = '#FFFB77';
            }
            else if (e.data.rideStatus === "Vehicle unassigned") {
                e.rowElement.style.backgroundColor = '#f2f2f2';
            }
            else if (e.data.rideStatus === "Driver Rejected Job") {
                e.rowElement.style.backgroundColor = '#f2f2f2';
            }
            else if (e.data.rideStatus === "On Location") {
                e.rowElement.style.backgroundColor = '#FFFF01';
            }
            else if (e.data.rideStatus === "Boarded") {
                e.rowElement.style.backgroundColor = '#D4FFD4';
            }
            else if (e.data.rideStatus === "NO SHOW") {
                e.rowElement.style.backgroundColor = '#eabdf5';
                e.rowElement.style.foregroundColor = '#ffffff';
            }
            else if (e.data.rideStatus === "NO SHOW - NON BILLABLE") {
                e.rowElement.style.backgroundColor = '#eabdf5';
                e.rowElement.style.foregroundColor = '#ffffff';
            }
            else if (e.data.rideStatus === "Ride Complete") {
                e.rowElement.style.backgroundColor = '#01B050';
                e.rowElement.style.foregroundColor = '#ffffff';
            }
        }
    }
    getReservations = async () => {
        // e.preventDefault();
        try {
            let airlineCode = sessionStorage.getItem('session_airlineCode');
            var request = {};
            request.UserId = sessionStorage.getItem('session_userId');
            request.AirlineCode = airlineCode;
            request.StatusId = 0;
            request.FromDate = formatDate(this.state.filterFromDatetime, 'MM/dd/yyyy') + " 00:00:00";
            request.ToDate = formatDate(this.state.filterFromDatetime, 'MM/dd/yyyy') + " 23:59:59";
            request.AirportCode = this.state.airportCode;
            const requestParams = getRequestParams("POST", request);
            this.setState({
                loadPanelVisible: true
            });
            let url = process.env.REACT_APP_API_BASE_URL + 'Rides/AirlineRides';
            const response = await fetch(url, requestParams);
            const data = await response.json();
            this.setState({
                reservationdatasource: data.payload !== null ? data.payload.filter(f => f.tripStatusId !== 118) : [],
                loadPanelVisible: false
            });

            this.setState({ loadPanelVisible: false })
        } catch (error) {
            console.log("Error in " + this.displayName + ".getReservations function", error);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    shceduleVsActualChartRender() {
        var daycategories = [];
        var totalrides = [];
        var totalcompletedrides = [];

        for (let i of this.state.scheduleVsActualData) {
            daycategories.push(i.dayName);
            totalrides.push(i.dayTotalRides);
            totalcompletedrides.push(i.totalCompleteRides);
        }

        let count1 = totalcompletedrides.filter(e => e > 0);
        let count2 = totalrides.filter(e => e > 0);

        Highcharts.chart('ScheduleVsActualChartContainer', {
            chart: {
                type: 'column'
            },
            title: {
                text: (count1.length > 0 || count2.length > 0) ? '' : 'No data available'
            },
            xAxis: {
                categories: daycategories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Rides'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y} Rides</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Total Rides',
                data: totalrides,
                color: "#cacaca"
            }, {
                name: 'Total Completed Rides',
                data: totalcompletedrides,
                color: "#7e7ecc"
            }]
        });
    }
    hourlyChartRender() {
        var daycategories = [];
        var gtperformancedata = [];

        for (let i of this.state.scheduleVsActualData) {
            daycategories.push(i.dayName);
            gtperformancedata.push(i.gtPerformance);
        }

        let count = gtperformancedata.filter(e => e > 0);

        Highcharts.chart('HourlyChartContainer', {
            chart: {
                type: 'line'
            },
            title: {
                text: count.length > 0 ? '' : 'No data available'
            },
            xAxis: {
                categories: daycategories
            },
            yAxis: {
                title: {
                    text: 'GT Performance (%)'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [{
                name: 'Day Name',
                data: gtperformancedata,
                color: "#FF6300"
            }],
            lang: {
                noData: 'no data!'
            },
            noData: {
                position: {
                    "x": 0,
                    "y": 0,
                    "align": "center",
                    "verticalAlign": "middle"
                }
            },
        });
    }

    AirportGridShow = () => {
        sessionStorage.setItem("session_PageName", "Airport");
        window.location.href = "/airport";
    }
    handleGridChanges = async (e, ids) => {
        e.preventDefault();
        let airlineCode = sessionStorage.getItem('session_airlineCode');
        var request = {};
        request.UserId = sessionStorage.getItem('session_userId');
        request.AirlineCode = airlineCode;
        request.StatusId = 0;
        request.FromDate = formatDate(this.state.filterFromDatetime, 'MM/dd/yyyy') + " 00:00:00";
        request.ToDate = formatDate(this.state.filterFromDatetime, 'MM/dd/yyyy') + " 23:59:59";
        request.AirportCode = this.state.airportCode;
        const requestParams = getRequestParams("POST", request);
        this.setState({
            loadPanelVisible: true
        });
        let url = process.env.REACT_APP_API_BASE_URL + 'Rides/AirlineRides';
        const response = await fetch(url, requestParams);
        const reservationdata = await response.json();
        const data = reservationdata.payload !== null ? reservationdata.payload.filter(f => f.tripStatusId !== 118) : [];
        if (ids === 1) {
            var CrewTransfer = [];
            CrewTransfer = data != null ? data.filter(e => e.tripStatusId !== 118) : [];
            this.setState({
                reservationdatasource: CrewTransfer,
                loadPanelVisible: false,
                reservationTitle: ""
            });
        } else if (ids === 2) {
            var DepartureRides = [];
            DepartureRides = data != null ? data.filter(e => e.rideTypeId === 2) : [];
            this.setState({
                reservationdatasource: DepartureRides,
                loadPanelVisible: false,
                reservationTitle: "Departure Rides"
            });
        }
        else if (ids === 3) {
            var ArrivalRides = [];
            ArrivalRides = data != null ? data.filter(e => e.rideTypeId === 1) : [];
            this.setState({
                reservationdatasource: ArrivalRides,
                loadPanelVisible: false,
                reservationTitle: "Arrival"
            });
        } else if (ids === 4) {
            var NoShowRides = [];
            NoShowRides = data != null ? data.filter(e => e.tripStatusId === 109 || e.tripStatusId === 110) : [];
            this.setState({
                reservationdatasource: NoShowRides,
                loadPanelVisible: false,
                reservationTitle: "No Show Rides"
            });
        } else if (ids === 5) {
            var CancelledRides = [];
            CancelledRides = data != null ? data.filter(e => e.tripStatusId === 111) : [];
            this.setState({
                reservationdatasource: CancelledRides,
                loadPanelVisible: false,
                reservationTitle: "Cancelled Rides"
            });
        } else if (ids === 6) {
            var DelayPickupRide = [];
            DelayPickupRide = data != null ? data.filter(e => e.onLocationTime > e.pickUpDateTime) : [];
            this.setState({
                reservationdatasource: DelayPickupRide,
                loadPanelVisible: false,
                reservationTitle: "Delay Pickup"
            });
        }
        else {
            var CompletedRides = [];
            CompletedRides = data != null ? data.filter(e => e.tripStatusId === 116 && e.tripStatusId !== 118) : [];
            this.setState({
                reservationdatasource: CompletedRides,
                loadPanelVisible: false,
                reservationTitle: "Executed Crew Transfers"
            });
        }

        this.setState({ loadPanelVisible: false })
    }
    render() {        
        return (
            <Fragment>
                <div className='content-wrapper'>
                    <div className='content pt-5'>
                        <div className='row mb-5'>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-card'>
                                    <div className='info-card-header'>
                                        <div className='card-icon bg-info'><i className="fas fa-cog"></i></div>
                                        <div className='card-content'>
                                            <p>Title</p>
                                            <h3>1500</h3>
                                        </div>
                                    </div>
                                    <div className='info-card-footer border-top'>
                                        <div><i className="fas fa-cog"></i> <span>Get info</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-card'>
                                    <div className='info-card-header'>
                                        <div className='card-icon bg-danger'><i className="fas fa-cog"></i></div>
                                        <div className='card-content'>
                                            <p>Title</p>
                                            <h3>1500</h3>
                                        </div>
                                    </div>
                                    <div className='info-card-footer border-top'>
                                        <div><i className="fas fa-cog"></i> <span>Get info</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-card'>
                                    <div className='info-card-header'>
                                        <div className='card-icon bg-warning'><i className="fas fa-cog"></i></div>
                                        <div className='card-content'>
                                            <p>Title</p>
                                            <h3>1500</h3>
                                        </div>
                                    </div>
                                    <div className='info-card-footer border-top'>
                                        <div><i className="fas fa-cog"></i> <span>Get info</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className='info-card'>
                                    <div className='info-card-header'>
                                        <div className='card-icon bg-success'><i className="fas fa-cog"></i></div>
                                        <div className='card-content'>
                                            <p>Title</p>
                                            <h3>1500</h3>
                                        </div>
                                    </div>
                                    <div className='info-card-footer border-top'>
                                        <div><i className="fas fa-cog"></i> <span>Get info</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 col-sm-4 col-md-4">
                                <div className="custom-card">
                                    <div className="custom-card-header bg-info">
                                        Featured
                                    </div>
                                    <div className="custom-card-body">
                                        <h5 className="custom-card-title">Special title treatment</h5>
                                        <p className="custom-card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-4">
                                <div className="custom-card">
                                    <div className="custom-card-header bg-danger">
                                        Featured
                                    </div>
                                    <div className="custom-card-body">
                                        <h5 className="custom-card-title">Special title treatment</h5>
                                        <p className="custom-card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-4">
                                <div className="custom-card">
                                    <div className="custom-card-header bg-warning">
                                        Featured
                                    </div>
                                    <div className="custom-card-body">
                                        <h5 className="custom-card-title">Special title treatment</h5>
                                        <p className="custom-card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}