import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Wrapper } from "@googlemaps/react-wrapper";

import { Layout } from './components/Layout';
import { AuthorisedLayout } from './components/AuthorisedLayout';
import { LoginComponent } from './components/LoginComponent';
import { DashboardComponent } from './components/dashboard/DashboardComponent';
import { GreeterDispatchComponent } from './components/dispatch/GreeterDispatchComponent';
import { DispatchComponent } from './components/dispatch/DispatchComponent';
import { UsersComponent } from './components/masters/UsersComponent';
import { CustomerUsersComponent } from './components/masters/CustomerUsersComponent';
import { UploadGreeterReservations } from './components/booking/UploadGreeterReservations';
import { ViewBookingComponent } from './components/booking/ViewBookingComponent';
import { NewGreeterBookingComponent } from './components/booking/NewGreeterBookingComponent';
// import { ShowGreeterBannerComponent } from './components/banners/ShowGreeterBannerComponent';
// import { BlnkGreeterBannerComponent } from './components/banners/BlnkGreeterBannerComponent';
import { BannerComponent } from './components/banners/BannerComponent';
import { CustomersComponent } from './components/masters/CustomersComponent';
import { ScheduleAuditLogComponent } from './components/auditlog/ScheduleAuditLogComponent';

export default class App extends Component {
    static displayName = App.name;

    render() {
        const isAuthorized = (sessionStorage.getItem('session_userId') === '' || sessionStorage.getItem('session_userId') === null) ? false : true;

        return (
            <Fragment>
                {
                    isAuthorized === true ?
                        <Wrapper apiKey={"AIzaSyB2__GLNvcvr70qg-p6lvN0O9H9XLhAU00"}>
                            <AuthorisedLayout>
                                <Route path='/dashboard' component={DashboardComponent} />
                                <Route path='/greeter' component={GreeterDispatchComponent} />
                                <Route path='/dispatch' component={DispatchComponent} />
                                <Route path='/users' component={UsersComponent} />
                                <Route path='/customerusers' component={CustomerUsersComponent} />
                                <Route path='/upload/GreeterReservations' component={UploadGreeterReservations} />
                                <Route path='/booking/view' component={ViewBookingComponent} />
                                <Route path='/booking/greeter/new' component={NewGreeterBookingComponent} />  
                                <Route path='/customers' component={CustomersComponent} />
                                <Route path='/auditlog/schedule' component={ScheduleAuditLogComponent} />
                            </AuthorisedLayout>
                        </Wrapper>
                        :
                        <Layout>
                            <Route exact path='/' component={LoginComponent} />
                            <Route path='/login' component={LoginComponent} />                            
                            <Route path='/banner' component={BannerComponent} />
                        </Layout>
                }
            </Fragment>
        );
    }
}