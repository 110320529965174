import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation,regEmail,vMinLength, vMinUpperCaseLetter, vMinLowerCaseLetter, vMinNumber, vMinSpecialCharacter, vIsPasswordComplexity } from '../../helpers/globalvalidations.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { ChangePasswordComponent } from '../common/ChangePasswordComponent';

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%',
    }
};

export class CustomerUsersComponent extends Component {
    static displayName = CustomerUsersComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            userSource: [],
            userModalIsOpen: false,
            singelData: null,
            id:0,
            userName:"",
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            userModal: false,
            userSource: [],
            userTypeList:[],
            firstName:"",
            lastName:"",
            accountId: 0,
            userTypeId: 0,
            userName:"",
            newPassword:"",
            confirmPassword:"",
            contactNo:"",
            email:"",
            //active: true,
            passwordEncryptKey:"",
            customers: [],
            customerId: 0,
            contactId: 0,
            statusId: 0,
            addressId: 0,
            errors:{
                firstName: "",
                lastName: "",
                userTypeId: 0,
                userName: "",
                newPassword: "",
                confirmPassword:"",
                contactNo: "",
                email: "",
                customerId: 0,
            }
        }
        this.getCustomerUsers = this.getCustomerUsers.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCustomerUsers();
        await this.getUserType();
        await this.getCustomers();
        this.setState({
            loadPanelVisible: false
        });
    }

    async getCustomers() {
        try {
            var request = {};            
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customers/dropdown', requestParams);
            const data = await response.json();
            this.setState({
                customers: data.customerDropdownList,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleUserModal = async (e) => {
        this.setState({
            userModal: true,
            singelData: null
        });        
    }

    getCustomerUsers = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.user_type = 0;
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;            
            request.user_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/customerusers/' + this.state.id, requestParams);
            const data = await response.json();            
            this.setState({
                userSource: data.users,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomerUsers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getUserType = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            let userId = 0; //parseInt(sessionStorage.getItem('session_userId'));            
            var request = "";
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/usertype/drodown', requestParams);
            const data = await response.json();
            this.setState({
                userTypeList: data.commonDropdownList,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getUserType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'userName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ userName: event.target.value });
                break;
            case 'firstName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ firstName: event.target.value });
                break;
            case 'lastName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ lastName: event.target.value });
                break;
            case 'email':
                    this.setState({ email: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        this.state.errors[name] = "";
    }

    handleUserTypeChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["userTypeId"] = "";
        }

        this.setState({
            userTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleCustomerChange = (selectedOption) => {
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["customerId"] = "";
        }

        this.setState({ 
            customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), 
        });       
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = 0;
            errors.userName = "";
            errors.userTypeId = "";
            errors.firstName = "";
            errors.lastName = "";
            errors.newPassword = "";
            errors.confirmPassword = "";
            errors.email = "";
            errors.contactNo = "";

            if (this.state.customerId === 0) {
                formIsValid = false;
                errors.customerId = "Please select Customer.";
            }
            if (this.state.userName.trim() === '') {
                formIsValid = false;
                errors.userName = "Please enter User Name.";
            }
            if (this.state.userTypeId === 0) {
                formIsValid = false;
                errors.userTypeId = "Please select user type.";
            }
            if (this.state.firstName.trim() === '') {
                formIsValid = false;
                errors.firstName = "Please enter first name.";
            }
            if (this.state.lastName.trim() === '') {
                formIsValid = false;
                errors.lastName = "Please enter last name.";
            }
            if (this.state.userTypeId === 0) {
                formIsValid = false;
                errors.userTypeId = "Please select user type.";
            }
            if (Number(this.state.id) === 0) {
                if (this.state.newPassword === '') {
                    formIsValid = false;
                    errors.newPassword = "Please enter password.";
                }
            }
            if (Number(this.state.id) === 0) {
                if (this.state.confirmPassword == '') {
                    formIsValid = false;
                    errors.confirmPassword = "Please enter re-type password.";
                }
            }
            if (Number(this.state.id) === 0) {
                if (this.state.confirmPassword !== this.state.newPassword) {
                    formIsValid = false;
                    errors.confirmPassword = "Password does not match.";
                }
            }

            if (this.state.contactNo === null || this.state.contactNo.trim() === "") {
                formIsValid = false;
                errors.contactNo = "Please enter mobile number.";
            } else if (this.state.contactNo.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").replaceAll(".", "").length != 10) {
                formIsValid = false;
                errors.contactNo = "Invalid mobile number.";
            }
            if (this.state.email === null || this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            } else if (this.state.email !== '') {
                if (!regEmail.test(this.state.email)) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                }
            }

            if (this.state.newPassword.trim() !== '' && vIsPasswordComplexity === true) {
                const renumbersymbolexp = /[^0-9]/g;
                const lowercaseexp = /[^a-z]/g;
                const uppercaseexp = /[^A-Z]/g;
                const specialcharacters = /[^!@#$%^&*]/g;

                var IsPwdnumbersymbol = this.state.newPassword.replace(renumbersymbolexp, "").length;
                var IsLowercase = this.state.newPassword.replace(lowercaseexp, "").length;
                var IsUppercase = this.state.newPassword.replace(uppercaseexp, "").length;
                var IsSpecialCharacters = this.state.newPassword.replace(specialcharacters, "").length;

                if (this.state.newPassword.length < vMinLength) {
                    formIsValid = false;
                    errors.newPassword = "Password must be " + vMinLength + " characters long";
                }
                else if (IsPwdnumbersymbol < vMinNumber) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinNumber + " number";
                }
                else if (IsLowercase < vMinLowerCaseLetter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinLowerCaseLetter + " lowercase alphabet";
                }
                else if (IsUppercase < vMinUpperCaseLetter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinUpperCaseLetter + " uppercase alphabet";
                }
                else if (IsSpecialCharacters < vMinSpecialCharacter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinSpecialCharacter + " special character";
                }
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleUserSubmit = async (e) => {
        e.preventDefault();
        try {        
            if (this.handleValidation()) {
                debugger;
                this.setState({ isSubmited: true });

                var newObject = {};
                newObject.user_id = parseInt(this.state.id);
                newObject.customer_id = parseInt(this.state.customerId);
                newObject.user_name = this.state.userName.trim();
                newObject.user_passcode = this.state.newPassword.trim();
                newObject.encrypt_passcode = "";
                newObject.encrypt_key = "";
                newObject.user_type_id = parseInt(this.state.userTypeId);
                newObject.user_address_id = 0;
                newObject.user_active_branch_id = parseInt(this.state.sessionUser.branchId);
                newObject.addresss = "";
                newObject.address_2 = "";
                newObject.district = "";
                newObject.district_id =  0;
                newObject.city_id = 0;
                newObject.state_id =  0;
                newObject.country_id = 0;
                newObject.zip_code = "";
                newObject.lattitude = 0.00;
                newObject.longitude = 0.00;
                newObject.geolocation = "";
                newObject.contact_title = "";
                newObject.first_name = this.state.firstName.trim();
                newObject.last_name = this.state.lastName.trim();
                newObject.country_code = "";
                newObject.area_code = "";
                newObject.phone_no = this.state.contactNo.trim();
                newObject.mobile_no = this.state.contactNo.trim();
                newObject.fax_no = "";
                newObject.email_id = this.state.email.trim();
                newObject.department_id = 0;
                newObject.designation_id = 0;    
                newObject.user_contact_id = parseInt(this.state.contactId);    
                newObject.status_id = parseInt(this.state.statusId);    
                newObject.user_address_id = parseInt(this.state.addressId); 
                newObject.timezone_id = 0;

                const requestParams = getRequestParams('POST', newObject);                
                const response = await fetch(process.env.REACT_APP_API_BASE_URL +  'master/' + this.state.id + '/user', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    Swal.fire({
                        icon: data.isSuccess ? 'info' : 'error',
                        title: data.isSuccess ? this.state.id > 0 ? "<small>Update Customer User</small>" : "<small>New Customer User</small>" : '<small>Oops...</small>',
                        text: data.outputmessage,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeUserModal();
                            setTimeout(() => this.getCustomerUsers(), 500);
                        }
                        this.setState({ isSubmited: false })
                    });

                    // toast.success(data.outputmessage + '!', {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    //     className: 'toast-message'
                    // });

                    // this.getUsers();
                    // this.closeUserModal();
                    // this.setState({ isSubmited: false })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })

                    // toast.danger(data.outputmessage + '!', {
                    //     position: toast.POSITION.BOTTOM_CENTER,
                    //     className: 'toast-message'
                    // }); //danger//info//warning
                    //this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleUserSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    closeUserModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            userModal: false,
            id: 0,
            firstName:"",
            lastName:"",
            accountId: 0,
            userTypeId: 0,
            userName:"",
            newPassword:"",
            confirmPassword:"",
            contactNo:"",
            customerId: 0,
            email:"",
            //active: false,
            passwordEncryptKey:"",
            contactId:0,
            statusId: 0,
            addressId: 0,
            errors:{
                firstName: "",
                lastName: "",
                userTypeId: 0,
                userName: "",
                newPassword: "",
                confirmPassword:"",
                contactNo: "",
                email: "",
                customerId: 0,
            }
        });
    }

    editUserModal = (e, data) => {
        e.preventDefault();
        if (data != null) {            
            debugger;
            this.setState({
                userModal: true,
                singelData: data,
                id: data.userId,
                firstName: data.firstName,
                lastName: data.lastName,
                accountId: data.accountId,
                userTypeId: data.userTypeId,
                userName: data.userName,
                contactNo: data.mobileNo,
                email: data.emailAddress,
                //active: data.active,
                customerId: data.customerId,
                contactId: data.contactId,
                statusId: data.statusId,
                addressId: data.userAddresId,
            });
        }
    }

    openchangesPasswordModel = (e, data) => {
        e.preventDefault();
        this.refs.changepasswordcomponent.showModal(data);
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editUserModal(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-key" title="Change Password" onClick={(e) => { this.openchangesPasswordModel(e, data.data) }} ></a>        
            </div>;
        }

        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Greeter Company</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                <button type='submit' className='btn btn-primary btn-sm' onClick={this.handleUserModal} title='New Customer User'><span><i className="fas fa-plus"></i> New User</span></button>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className='card card-grid'>
                            <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.userSource}
                                    keyExpr="userId"
                                    id='gridHsnCodeMasterList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    width="100%"
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="firstName" caption="First Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="lastName" caption="Last Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="userName" caption="User Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="userTypeName" caption="User Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="mobileNo" caption="Contact#" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="emailAddress" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={270} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="users" />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.userModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleUserSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update User" : "New User"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Select Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customers.length > 0 ? this.state.customers : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%"
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>User Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.userTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select user type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.userTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleUserTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["userTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>User Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="userName" placeholder="Enter user name" value={this.state.userName} 
                                        disabled={this.state.id > 0} title="User Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["userName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userName"]}</span>}
                                    </div>
                                </div>
                                {
                                    this.state.id === 0 ?
                                        <div className="col-lg-8">
                                            <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Password <span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control form-control-sm" name="newPassword" placeholder="Enter password" value={this.state.newPassword}
                                                        title="Password" maxLength="150" onChange={this.handleInputChange} />
                                                    {this.state.errors["newPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["newPassword"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Re-type Password <span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control form-control-sm" name="confirmPassword" placeholder="Re-type password" value={this.state.confirmPassword}
                                                        title="Confirm Password" maxLength="150" onChange={this.handleInputChange} />
                                                    {this.state.errors["confirmPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["confirmPassword"]}</span>}
                                                </div>
                                            </div>
                                            </div>
                                            
                                        </div> : null
                                }                                
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" placeholder="e.g. John" value={this.state.firstName} 
                                        title="First Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["firstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" placeholder="e.g. Dave" value={this.state.lastName} 
                                        title="Last Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["lastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Contact No <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="contactNo" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.contactNo} 
                                        onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["contactNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" placeholder="e.g. user@domain.com" value={this.state.email} 
                                        title="Email" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>                                
                                {/* <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{ display: this.state.id <= 0 ? 'none' : 'block', marginTop: '19px' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="active" name="active" checked={this.state.active} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="active" style={{ fontSize: "inherit" }}> Active &nbsp;</label>
                                        </div>
                                    </div>
                                </div> */}
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleUserSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeUserModal} title="Close"><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <ChangePasswordComponent ref="changepasswordcomponent" />
            </Fragment>
        )
    };
}