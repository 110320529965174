import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';

const position = { of: '#historydiv' };

export class ScheduleAuditLogComponent extends Component {
    static displayName = ScheduleAuditLogComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            sessionUser: JSON.parse(sessionStorage.getItem("session_user")),
            scheduleLog: [],
        }
        this.getScheduleLog = this.getScheduleLog.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getScheduleLog();
        this.setState({
            loadPanelVisible: false
        });
    }

    async getScheduleLog(){
        try {
            var request = {};
            request.service_provider_id = parseInt(this.state.sessionUser.accountId);
            request.customer_id = 0;
            request.user_id = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'auditlog/schedule', requestParams);
            const data = await response.json();
            debugger;
            this.setState({
                scheduleLog: data.auditLogSchedule,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getScheduleLog function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-light">Schedule Audit Log</h1>
                                </div>
                                {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                    <button type='submit' className='btn btn-primary btn-sm' onClick={this.handleUserModal} title='New User'><span><i className="fas fa-plus"></i> New User</span></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className='card card-grid'>
                            <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.scheduleLog}
                                    keyExpr="id"
                                    id='gridAuditLogSchedule'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    width="100%"
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="service_provider_name" caption="Service Provider Name" allowSearch={true} allowFiltering={false} allowSorting={true} 
                                        width={300} />
                                    <Column dataField="customer_name" caption="Customer Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                    <Column dataField="no_of_bookings" caption="No of Bookings" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="full_name" caption="Uploaded By" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="uploaded_date" caption="Uploaded Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                        dataType="datetime" format="MM/dd/yyyy HH:mm" />
                                    <Column dataField="status" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={450} />                                    
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="users" />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}